import React, { useEffect, useRef, useState } from "react"
import AxiosInstance from "../../../../services/axiosInstance"
import { getLoginUserId } from "../../../../services/auth"

const FeaturedImage = ({ featureImg, setFeatureImg }) => {
  const [file, setFile] = useState(null),
    [uploadFile, setUploadFile] = useState(false)

  const featureImgSelect = useRef(null)

  const openSelectLogoBox = () => {
    featureImgSelect.current.click()
  }

  const handleFeatureImg = e => {
    setFile(e.target.files[0])
    setUploadFile(true)
  }

  const uploadFileData = file => {
    return new Promise(async () => {
      let formData = new FormData()
      formData.append("ProfilePic", file)
      formData.append("user_id", getLoginUserId())
      formData.append("parent_id", "0")
      await AxiosInstance.post(`/settings/general-setting/upload-file-r2`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then(function (response) {
          setFeatureImg(response.data.profilePic.filePath)
        })
        .catch(function (error) {})
    })
  }

  useEffect(() => {
    if (uploadFile) {
      setUploadFile(false)
      uploadFileData(file)
    }
  })

  return (
    <div className="dh-block-body">
      <div className="folderIcon">
        <img
          src={`${process.env.GATSBY_WRANGLER_URL}${featureImg ? featureImg : "StaticImages/no-image.jpg"}`}
        />
      </div>
      <input type="file" ref={featureImgSelect} onChange={handleFeatureImg} hidden />
      <button type="button" onClick={openSelectLogoBox} className="btn btn-default btnFeaturedImgEdit">
        <span data-bs-toggle="tooltip" data-bs-title="Edit folder image">
          <i className="bi bi-pencil-fill" />
        </span>
      </button>
    </div>
  )
}

export default FeaturedImage
