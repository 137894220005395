class Phone {
  static get toolbox() {
    return {
      title: "Phone",
      icon: '<i class="bi bi-phone"></i>',
    }
  }

  render() {
    var newSpan = document.createElement("span")
    newSpan.innerHTML = '[swift_phone country="US" phone_type="Mobile" role="Test6" required]'
    return newSpan
  }

  save(blockContent) {
    return {
      text: blockContent.innerText,
    }
  }
}

export default Phone
