import React, { useEffect, useState } from "react"
import { OverlayTrigger, Tooltip } from "react-bootstrap"

const SEODescription = ({ seoDescription }) => {
  const characterLength = 160
  const [count, setCount] = useState(0)

  useEffect(() => {
    setCount(characterLength - seoDescription.length)
  }, [seoDescription])

  return (
    <div className="col-lg-12 mb-3">
      <div className="colHeading">
        <strong>
          <i className="bi bi-file-image" /> SEO Description{" "}
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip>
                Invoke curiosity, show payoff, make us want to click! This shows on Google, Bing, etc.
              </Tooltip>
            }
          >
            <i className="bi bi-question-circle-fill" />
          </OverlayTrigger>
        </strong>
      </div>
      <div className="dh-block-body">
        <textarea
          className="form-control seo_desc"
          defaultValue={seoDescription}
          maxLength={characterLength}
          name="seo_description"
          onChange={e => setCount(characterLength - e.target.value.length)}
        />
        <span className="seo_desc_counter">{count} remaining</span>
      </div>
    </div>
  )
}

export default SEODescription
