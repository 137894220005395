import React, { useEffect, useState } from "react"
import { Accordion, Form, OverlayTrigger, Tooltip } from "react-bootstrap"
import "../../../css/bootstrap-tagsinput.css"
import Language from "./MoreOptions/language"
import AdvancedSettings from "./MoreOptions/advancedSettings"
import SignerAuthentication from "./MoreOptions/signerAuthentication"
import Privacy from "./MoreOptions/privacy"
import Tags from "../Doc/MoreOptions/tags"
import Comments from "./MoreOptions/comments"
import OutputPDFAdvancedOptions from "./MoreOptions/outputPDFAdvancedOptions"
import AxiosInstance from "../../../services/axiosInstance"
import FeaturedImage from "./MoreOptions/featuredImage"
import SEODescription from "./MoreOptions/seoDescription"
const MoreOptions = ({
  driveType,
  spamReduction,
  docType,
  setDocType,
  esignMode,
  setEsignMode,
  tags,
  setTags,
  tempTags,
  setTempTags,
  pdfGeneration,
  setPDFGeneration,
  signAuth,
  setSignAuth,
  docPrivacy,
  setDocPrivacy,
  whoCanModify,
  setWhoCanModify,
  privacyPassword,
  whoCanCopy,
  setWhoCanCopy,
  copyPassword,
  setCopyPassword,
  copyPayment,
  setCopyPayment,
  updateData,
  emailVerification,
  setEmailVerification,
  setSendSMSVerification,
  sendSMSVerification,
  signInFG,
  setSignInFG,
  uploadGOVId,
  setUploadGOVId,
  geoLocation,
  setGEOLocation,
  photoSignor,
  setPhotoSignor,
  language,
  docPrint,
  fontSize,
  docComments,
  setDocComments,
  commentVisibility,
  setCommentVisibility,
  esignSaveData,
  setEsignSaveData,
  appendDoc,
  setAppendDoc,
  appendUpload,
  setAppendUpload,
  applyStamp,
  setApplyStamp,
  outputPdfFileType,
  previewUrl,
  featureImg,
  setFeatureImg,
  seoDescription,
  setShowLegacyEditor,
  showMoreDetailsPanel,
}) => {
  const [domainCall, setDomainCall] = useState(true),
    [spamOptions, setSpamOptions] = useState([])

  useEffect(() => {
    if (domainCall) {
      setDomainCall(false)
      getDomains()
    }
  })
  const getDomains = async () => {
    await AxiosInstance.get(`/settings/general-setting/work-domain`).then(response => {
      setSpamOptions(response.data.data)
    })
  }

  return (
    <div className={`col-lg-12 mb-3${!showMoreDetailsPanel ? " d-none" : ""}`} id="more_panel">
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0" className="panel panel-grey">
          <Accordion.Header>
            <span className="panel-heading-icon">
              <i className="bi bi-three-dots" />
            </span>
            <span className="panel-heading-title">More Options</span>
          </Accordion.Header>
          <Accordion.Body>
            <div className="row">
              <div className="col-lg-6">
                {["doc", "e_sign"].includes(driveType) && (
                  <div className="row">
                    <div className="col-lg-12 mb-3 docAdvSettingsContainer">
                      <div className="colHeading">
                        <strong>
                          <i className="bi bi-gear-fill" /> Advanced Settings
                        </strong>
                      </div>
                      <AdvancedSettings
                        docType={docType}
                        setDocType={setDocType}
                        esignMode={esignMode}
                        setEsignMode={setEsignMode}
                        pdfGeneration={pdfGeneration}
                        setPDFGeneration={setPDFGeneration}
                        setShowLegacyEditor={setShowLegacyEditor}
                      />
                    </div>
                  </div>
                )}
                {["doc", "e_sign"].includes(driveType) && (
                  <div className="col-lg-12 mb-3 docTypeOpt docTypeOpteSignDoc">
                    <div className="colHeading">
                      <strong>
                        <i className="bi bi-person-circle" /> Signer Authentication
                      </strong>
                    </div>
                    <SignerAuthentication
                      signAuth={signAuth}
                      setSignAuth={setSignAuth}
                      emailVerification={emailVerification}
                      setEmailVerification={setEmailVerification}
                      setSendSMSVerification={setSendSMSVerification}
                      sendSMSVerification={sendSMSVerification}
                      signInFG={signInFG}
                      setSignInFG={setSignInFG}
                      uploadGOVId={uploadGOVId}
                      setUploadGOVId={setUploadGOVId}
                      geoLocation={geoLocation}
                      setGEOLocation={setGEOLocation}
                      photoSignor={photoSignor}
                      setPhotoSignor={setPhotoSignor}
                    />
                  </div>
                )}
                {driveType === "form" && (
                  <div className="col-lg-12 mb-3 spamReduction">
                    <div className="colHeading">
                      <strong>
                        <i className="bi bi-clock" /> Spam Reduction
                      </strong>
                    </div>
                    <div className="dh-block-body">
                      <div className="mb-1">
                        Select the domain which is help to spam reduction
                        <span>
                          <i className="bi bi-question-circle-fill" />
                        </span>
                      </div>
                      <div className="mb-1">
                        <Form.Select
                          className="form-select"
                          name="spam_reduction"
                          defaultValue={spamReduction}
                          key={spamReduction}
                        >
                          {spamOptions.length > 0 &&
                            spamOptions.map((options, index) => {
                              return (
                                <option key={index} value={options.value}>
                                  {options.value}
                                </option>
                              )
                            })}
                        </Form.Select>
                      </div>
                    </div>
                  </div>
                )}
                <div className="col-lg-12 mb-3">
                  <div className="colHeading">
                    <strong>
                      <i className="bi bi-tags-fill" /> Tags
                    </strong>
                  </div>
                  <div className="dh-block-body">
                    <Tags tags={tags} setTags={setTags} tempTags={tempTags} setTempTags={setTempTags} />
                  </div>
                </div>

                <div className="col-lg-12 mb-3 docPrivacyContainer">
                  <div className="colHeading">
                    <strong>
                      <i className="bi bi-lock-fill" /> Privacy
                    </strong>
                  </div>
                  <Privacy
                    docPrivacy={docPrivacy}
                    setDocPrivacy={setDocPrivacy}
                    whoCanModify={whoCanModify}
                    setWhoCanModify={setWhoCanModify}
                    privacyPassword={privacyPassword}
                    whoCanCopy={whoCanCopy}
                    setWhoCanCopy={setWhoCanCopy}
                    copyPassword={copyPassword}
                    setCopyPassword={setCopyPassword}
                    previewUrl={previewUrl}
                    copyPayment={copyPayment}
                    setCopyPayment={setCopyPayment}
                  />
                </div>

                {["doc", "e_sign"].includes(driveType) && (
                  <div className="col-lg-12 mb-3 docPrivacyContainer">
                    <div className="colHeading">
                      <strong>
                        <i className="bi bi-clock" /> Dynamic Data
                      </strong>
                    </div>
                    <div className="dh-block-body">
                      <div className="mb-1">
                        Update dynamic data within the doc
                        <span>
                          <i className="bi bi-question-circle-fill" />
                        </span>
                      </div>
                      <div className="mb-1">
                        <Form.Select
                          className="form-select"
                          defaultValue={updateData}
                          key={updateData}
                          name="update_data"
                        >
                          <option value="Disabled">Disabled</option>
                          <option value="Monthly">Monthly</option>
                          <option value="Weekly">Weekly</option>
                          <option value="Daily">Daily</option>
                          <option value="4 hours">4 hours</option>
                          <option value="Hourly">Hourly</option>
                        </Form.Select>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="col-lg-6">
                <div className="row">
                  <div className="col-lg-12 mb-3">
                    <div className="colHeading">
                      <strong>
                        <i className="bi bi-file-image" /> Featured Image
                      </strong>
                    </div>
                    <FeaturedImage featureImg={featureImg} setFeatureImg={setFeatureImg} />
                  </div>

                  {driveType === "doc" && <SEODescription seoDescription={seoDescription} />}

                  <div className="col-lg-12 mb-3">
                    <div className="colHeading">
                      <strong>
                        <i className="bi bi-chat-fill" /> Comments
                      </strong>
                    </div>
                    <Comments
                      docComments={docComments}
                      setDocComments={setDocComments}
                      commentVisibility={commentVisibility}
                      setCommentVisibility={setCommentVisibility}
                    />
                  </div>
                  {["doc", "e_sign"].includes(driveType) && (
                    <div className="col-lg-12 mb-3 docTypeOpt docTypeOpteSignDoc docPrivacyContainer">
                      <div className="colHeading">
                        <strong>
                          <i className="bi bi-person-circle" /> Sessions, Multi-Signer & Roles Automation
                        </strong>
                      </div>
                      <div className="dh-block-body">
                        <div className="row row-cols-lg-auto g-2 align-items-center">
                          <div className="col-12">This is completed by</div>
                          <div className="col-12">
                            <Form.Select className="form-select roleSignedByOpt" name="rolesAutomation">
                              <option value="onlyOne">1 role</option>
                              <option value="moreThanOne">{">"} 1 roles</option>
                            </Form.Select>
                          </div>
                          <div className="col-12 moreThenOneOpt d-none">in</div>
                          <div className="col-12 moreThenOneOpt d-none">
                            <Form.Select
                              name="signPartyOrder"
                              id="sign_party_order"
                              defaultValue={"any"}
                              key={"any"}
                              className="form-select sign_party_order"
                            >
                              <option value="any">any</option>
                              <option value="specific">specific</option>
                            </Form.Select>
                          </div>
                          <div className="col-12 moreThenOneOpt d-none">order.</div>
                        </div>
                        <div className="moreThenOneOpt mt-2 d-none">
                          <p>
                            Note: This is just for automation, your fields above need to define the roles to
                            correspond.
                            <a href="https://crm.swiftcrm.com/support/roles-automation" target="_blank">
                              See here for more info.
                            </a>
                          </p>
                        </div>
                        <div className="specificTeamSelection d-none">
                          <div className="row mt-2">
                            <div className="col-lg-12">
                              <div className="specificTeamSelectionContainer sortable">
                                <div className="row teamMember align-items-center teamRole_1">
                                  <div className="col-lg-12">
                                    <div className="specificMemberBox">
                                      <span className="btnSpecificTeamDelete" data-role="1">
                                        <i className="bi bi-x-lg" />
                                      </span>
                                      <span className="btnSpecificTeamMover">
                                        <i className="bi bi-arrow-down-up" />
                                      </span>
                                      <div className="specificTeamAction">
                                        <span className="numberCol">1</span>
                                      </div>
                                      <div className="align-items-center py-2 roleBox">
                                        <div className="row row-cols-lg-auto g-2 align-items-center">
                                          <div className="col-12">
                                            <Form.Select
                                              className="form-select roleSelect"
                                              name="teamRole_1"
                                              id="teamRole_1"
                                            >
                                              <option>== List of roles ==</option>
                                            </Form.Select>
                                          </div>
                                          <div className="col-12">notify with</div>
                                          <div className="col-12">
                                            <Form.Select
                                              name="marketingSequence[]"
                                              className="form-select marketingSequence"
                                            >
                                              <option value="Default">Default System Notification</option>
                                              <option value="" disabled="disabled">
                                                --------------------
                                              </option>
                                              <option value="Custom">Custom Sequences</option>
                                            </Form.Select>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="ftNote">
                                        ...then we'll forward this for signature to...
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row mt-2">
                                <div className="col-lg-12 text-center">
                                  <button
                                    type="button"
                                    className="btnAddNewGreenDashed"
                                    id="addRoleAutomation"
                                    data-bs-toggle="tooltip"
                                    data-bs-title="Add New Role Automation"
                                  >
                                    <i className="bi bi-plus-lg" />
                                  </button>
                                </div>
                                <div className="col-lg-12 text-center">
                                  <div className="">
                                    <strong>
                                      <i className="bi bi-check2-square" /> then we'll mark this as complete.
                                    </strong>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row mt-3 docTypeOpt docTypeOpteSignDoc docTypeOptjobPostOpening docTypeOptRFP docTypeOptform docTypeOptquiz docTypeOptpoll docTypeOptelection docTypeOptregularDoc docTypeOptMini docTypeOptvLandigPage docTypeOptvPSUPage docTypeOptvOnDemandPage docTypeOptwebpage docTypeOptFullscreen">
                          <div className="col-lg-12 esignSaveData">
                            Allow user to save incomplete forms{" "}
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip>
                                  Allow user to save an incomplete form and return to it later. Recommended
                                  for complex forms or applications that may require the user to gather
                                  assets. User will create a password to login & resume later if needed.
                                </Tooltip>
                              }
                            >
                              <span
                                data-bs-toggle="tooltip"
                                data-bs-title="Allow user to save an incomplete form and return to it later. Recommended for complex forms or applications that may require the user to gather assets. User will create a password to login &amp; resume later if needed."
                              >
                                <i className="bi bi-question-circle-fill" />
                              </span>
                            </OverlayTrigger>
                            <div className="toggleSwitch">
                              <Form.Control
                                type="checkbox"
                                value={true}
                                name="esign_save_data"
                                id="esignSaveData"
                                checked={esignSaveData === "true" || esignSaveData === true ? "checked" : ""}
                                onChange={event => {
                                  setEsignSaveData(event.target.checked)
                                }}
                              />
                              <label></label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <Language language={language} />
                  {["doc", "e_sign"].includes(driveType) && (
                    <div className="col-lg-12 mb-3 docTypeOpt docTypeOptembedform docTypeOptform docTypeOpteSignDoc docTypeOptRFP docTypeOptquiz docTypeOptelection docTypeOptvPSUPage docTypeOptFullscreen">
                      <div className="colHeading">
                        <strong>
                          <i className="bi bi-file-earmark-pdf-fill" /> Output PDF Advanced Options
                        </strong>
                      </div>
                      <OutputPDFAdvancedOptions
                        appendDoc={appendDoc}
                        setAppendDoc={setAppendDoc}
                        appendUpload={appendUpload}
                        setAppendUpload={setAppendUpload}
                        applyStamp={applyStamp}
                        setApplyStamp={setApplyStamp}
                        outputPdfFileType={outputPdfFileType}
                      />
                    </div>
                  )}
                  {["doc", "e_sign"].includes(driveType) && (
                    <div className="col-lg-12 mb-3">
                      <div className="colHeading">
                        <strong>
                          <i className="bi bi-printer-fill" /> Print
                        </strong>
                      </div>
                      <div className="dh-block-body">
                        <div className="mb-3">
                          Add Print Option
                          <Form.Select
                            name="doc_print"
                            className="form-select"
                            defaultValue={docPrint}
                            key={docPrint}
                          >
                            <option value="off">Off</option>
                            <option value="add-sign-send">Add to Sign & Send</option>
                            <option value="show-print-only">Show Print Only</option>
                          </Form.Select>
                        </div>
                        <div className="">
                          Font Size
                          <Form.Select
                            name="font_size"
                            className="form-select"
                            defaultValue={fontSize}
                            key={fontSize}
                          >
                            <option value="small">Small</option>
                            <option value="medium">Medium</option>
                            <option value="large">Large</option>
                          </Form.Select>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  )
}

export default MoreOptions
