import * as React from "react"
import PrivateRoute from "../../../components/privateRoute"
import DocDetail from "../../../components/Drive/Doc/Detail.js"
import ReduxWrapper from "../../../redux/reduxWrapper"

const DocDetails = props => {
  return <PrivateRoute component={DocDetail} location={props.location} selectedDocId={props.id} />
}

const WrappedPage = props => <ReduxWrapper element={<DocDetails {...props} />} />
export default WrappedPage
